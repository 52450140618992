import React, { useEffect, useState } from "react";
import { HiOutlineMail } from "react-icons/hi";
import { LuTwitter } from "react-icons/lu";
import { TiSocialLinkedin } from "react-icons/ti";
import styled from "styled-components";
import { RowDiv } from "../components/Layout";
import "./app.css";
import "./assets/SuperCool.ttf";

import useWindowDimensions from "../hooks/useWindowDimensions";
import Logo from "./assets/logo2.png";

interface Props {}

const Home: React.FC<Props> = () => {
  const [savedToClipboard, setSavedToClipboard] = useState(false);
  const { size } = useWindowDimensions();

  useEffect(() => {
    if (savedToClipboard)
      setTimeout(() => {
        setSavedToClipboard(false);
      }, 1000);
  }, [savedToClipboard]);

  const saveToClipboard = () => {
    navigator.clipboard.writeText("daniel@friedebold.net");
    setSavedToClipboard(true);
  };

  return (
    <Background>
      {/* <h1 id="name">FRIEDEBOLD</h1> */}
      <img
        style={{ width: size === "mobile" ? 250 : 350, userSelect: "none" }}
        src={Logo}
        draggable={false}
      />

      <RowDiv
        style={{
          gap: 10,
          margin: 20,
          marginTop: 0,
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <ContactButton onClick={() => saveToClipboard()}>
          {!savedToClipboard ? (
            <HiOutlineMail />
          ) : (
            <p>copied to clipboard...</p>
          )}
        </ContactButton>
        <a target="none" href={"https://www.linkedin.com/in/friedebold/"}>
          <ContactButton>
            <TiSocialLinkedin />
          </ContactButton>
        </a>
        <a target="none" href={"https://x.com/friedebold/"}>
          <ContactButton>
            <LuTwitter />
          </ContactButton>
        </a>
      </RowDiv>
    </Background>
  );
};

const Background = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: black;
  align-items: center;
  justify-content: center;
  gap: 32px;
`;

const ContactButton = styled.div`
  border: 1px solid rgba(236, 216, 174, 0.2);
  padding: 10px 10px;
  border-radius: 0px;
  text-decoration: none;
  color: #837f78;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;
export default Home;
