import { motion, useScroll, useSpring, useTransform } from "motion/react";
import React from "react";
import styled from "styled-components";
import { ColDiv } from "../../../components/Layout";
import { H1 } from "../../../components/Typography";
import { useInitialDivHeight } from "../../../hooks/useInitialDivHeight";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { ImageProps } from "../../App";
import { Config } from "../../constants";
import Canvas from "./Canvas";

interface Props {
  config: Config;
  IMAGES: {
    [key: string]: ImageProps[];
  };
}

const ViewPanel: React.FC<Props> = ({ config, IMAGES }) => {
  const { size } = useWindowDimensions();
  const { scrollY } = useScroll();

  const { ref, height } = useInitialDivHeight();

  const tileTop = useTransform(scrollY, [0, 60], [0, 0]);
  const titleOpacity = useTransform(scrollY, [0, 50], [1, 0]);

  const canvasHeight = useTransform(scrollY, [0, 500], [height, height - 100]);
  const smoothHeight = useSpring(canvasHeight, { stiffness: 250, damping: 30 });
  const canvasOpacity = useTransform(scrollY, [150, 400], [1, 0.4]);

  return (
    <Background
      style={{ top: size === "mobile" ? -23 : 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: 0.3 } }}
      exit={{ opacity: 0 }}
    >
      <TitleWrapper
        style={{
          opacity: titleOpacity,
          translateY: tileTop,
          marginLeft: 20,
          marginTop: 20,
        }}
      >
        <H1>Pilot Type-A</H1>
      </TitleWrapper>
      <div
        style={
          height > 0
            ? {
                height: height,
              }
            : {}
        }
      >
        <motion.div
          ref={ref}
          style={{
            height: height > 0 ? smoothHeight : undefined,
            opacity: canvasOpacity,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <Canvas config={config} {...{ IMAGES }} />
        </motion.div>
      </div>
    </Background>
  );
};

const Background = styled(motion(ColDiv))`
  align-self: start;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  position: sticky;
`;

const TitleWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  height: 50px;
  justify-content: flex-start;
  align-items: center;
`;

export default ViewPanel;
