import React from "react";
import styled from "styled-components";
import { ImageProps } from "../../App";
import { Config } from "../../constants";

interface Props {
  config: Config;
  IMAGES: {
    [key: string]: ImageProps[];
  };
}

const Canvas: React.FC<Props> = ({ config, IMAGES }) => {
  return (
    <Background>
      <InnerCanvas caseSize={Number(config.case.diameter) || 45}>
        {IMAGES.strap.map((imageProps, index) => {
          const { value, image } = imageProps;
          const isActive = config.strap.type === value;
          return (
            <Dial key={index}>
              <FadeImage src={image} isActive={isActive} />
            </Dial>
          );
        })}
        {IMAGES.dial.map((imageProps, index) => {
          const { value, image } = imageProps;
          const isActive = `${config.dial.color}-${config.dial.date}` === value;
          return (
            <Dial key={index}>
              <FadeImage src={image} isActive={isActive} />
            </Dial>
          );
        })}
        {IMAGES.case.map((imageProps, index) => {
          const { value, image } = imageProps;
          const isActive = config.case.color === value;
          return (
            <Dial key={index}>
              <FadeImage src={image} isActive={isActive} />
            </Dial>
          );
        })}
        {IMAGES.crown.map((imageProps, index) => {
          const { value, image } = imageProps;
          const isActive =
            `${config.case.color}-${config.case.handedness}` === value;
          return (
            <Dial key={index}>
              <FadeImage src={image} isActive={isActive} />
            </Dial>
          );
        })}
        {IMAGES.logo.map((imageProps, index) => {
          const { value, image } = imageProps;
          const isActive = `${config.dial.color}-${config.dial.logo}` === value;
          return (
            <Dial key={index}>
              <FadeImage src={image} isActive={isActive} />
            </Dial>
          );
        })}
        {IMAGES.hands.map((imageProps, index) => {
          const { value, image } = imageProps;
          const isActive = config.dial.hands === value;
          return (
            <Dial key={index}>
              <FadeImage src={image} isActive={isActive} />
            </Dial>
          );
        })}
      </InnerCanvas>
    </Background>
  );
};

const Background = styled.div`
  height: 100%;
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  user-select: none;
  pointer-events: none;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 30px;
`;

const InnerCanvas = styled.div<{ caseSize: number }>`
  aspect-ratio: 2/3;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: auto;
  height: ${(props) => `${(props.caseSize / 45) * 80}%`};
  transition: height 0.3s ease-in-out;
`;
const FadeImage = styled.img<{ isActive?: boolean }>`
  width: 100%;
  opacity: ${(props) => (props.isActive ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  position: absolute;
`;

const Dial = styled.div`
  width: 100%;
  height: auto;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export default Canvas;
