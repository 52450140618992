export interface Option {
  value: string;
  extra: { displayName: string; detailedName: string; colorHex?: string };
}

export interface ConfigOptions {
  case: CaseOptions;
  movement: MovementOptions;
  dial: DialOptions;
  strap: StrapOptions;
}

export interface CaseOptions {
  diameter: Option[];
  color: Option[];
  handedness: Option[];
}

export interface MovementOptions {
  type: Option[];
}

export interface DialOptions {
  color: Option[];
  hands: Option[];
  date: Option[];
  logo: Option[];
}

export interface StrapOptions {
  type: Option[];
}

export interface Config {
  case: {
    diameter: string;
    color: string;
    handedness: string;
  };
  movement: {
    type: string;
  };
  dial: {
    color: string;
    hands: string;
    date: string;
    logo: string;
  };
  strap: { type: string };
}

export const CONFIG_OPTIONS: ConfigOptions = {
  case: {
    diameter: [
      { value: "36", extra: { displayName: "36 mm", detailedName: "36 mm" } },
      { value: "39", extra: { displayName: "39 mm", detailedName: "39 mm" } },
      { value: "42", extra: { displayName: "42 mm", detailedName: "42 mm" } },
      { value: "45", extra: { displayName: "45 mm", detailedName: "45 mm" } },
    ],
    color: [
      {
        value: "steel",
        extra: {
          displayName: "Steel",
          detailedName: "Stainless Steel",
          colorHex: "#9C9C9A",
        },
      },
      {
        value: "black",
        extra: {
          displayName: "Black",
          detailedName: "Black",
          colorHex: "#000000",
        },
      },
      {
        value: "bronze",
        extra: {
          displayName: "Bronze",
          detailedName: "Bronze",
          colorHex: "#D29C6E",
        },
      },
    ],
    handedness: [
      {
        value: "left",
        extra: { displayName: "Left", detailedName: "Left-handed" },
      },
      {
        value: "right",
        extra: { displayName: "Right", detailedName: "Right-handed" },
      },
    ],
  },
  movement: {
    type: [
      {
        value: "automatic",
        extra: { displayName: "Automatic", detailedName: "Automatic" },
      },
      {
        value: "manual",
        extra: { displayName: "Hand-wounded", detailedName: "Hand-wounded" },
      },
    ],
  },
  dial: {
    color: [
      {
        value: "black",
        extra: {
          displayName: "Black",
          detailedName: "Black Color",
          colorHex: "#000000",
        },
      },
      {
        value: "blue",
        extra: {
          displayName: "Blue",
          detailedName: "Blue Color",
          colorHex: "#355CA1",
        },
      },
      {
        value: "white",
        extra: {
          displayName: "White",
          detailedName: "White Color",
          colorHex: "#FFFFFF",
        },
      },
    ],
    hands: [
      {
        value: "black",
        extra: {
          displayName: "Black",
          detailedName: "Black Hands",
          colorHex: "#000000",
        },
      },
      {
        value: "blue",
        extra: {
          displayName: "Blue",
          detailedName: "Blue Hands",
          colorHex: "#355CA1",
        },
      },
    ],
    date: [
      {
        value: "without",
        extra: { displayName: "No Date", detailedName: "Without Date" },
      },
      {
        value: "with",
        extra: { displayName: "With Date", detailedName: "With Date" },
      },
    ],
    logo: [
      {
        value: "with",
        extra: { displayName: "With Logo", detailedName: "With Logo" },
      },
      {
        value: "without",
        extra: { displayName: "No Logo", detailedName: "Without Logo" },
      },
    ],
  },
  strap: {
    type: [
      {
        value: "steel",
        extra: {
          displayName: "Steel",
          detailedName: "Stainless Steel",
          colorHex: "#9C9C9A",
        },
      },
      {
        value: "black",
        extra: {
          displayName: "Black",
          detailedName: "Black Leather",
          colorHex: "#000000",
        },
      },
      {
        value: "brown",
        extra: {
          displayName: "Brown",
          detailedName: "Brown Leather",
          colorHex: "#874E3A",
        },
      },
    ],
  },
};
