import React from "react";
import styled from "styled-components";

interface Props {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  ref?: React.RefObject<HTMLDivElement>;
}

const Tile: React.FC<Props> = ({ children, style, ref }) => {
  return (
    <Background style={style} ref={ref}>
      {children}
    </Background>
  );
};

const Background = styled.div`
  background: rgba(255, 255, 255, 0.03);
  will-change: filter;
  border-radius: 35px;
  backdrop-filter: blur(12px);
  padding: 20px 20px;
  justify-content: center;
  display: flex;
  //  box-shadow: inset 0px 0px 2px 3px rgba(255, 255, 255, 0.02);
  z-index: 1;
`;

export default Tile;
