import { motion } from "motion/react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { capitalize } from "../helper";

interface Props {
  route: string;
  isFullSize?: boolean;
}

const NavItem: React.FC<Props> = ({ route, isFullSize }) => {
  const location = useLocation();
  return (
    <HeaderItem
      isActive={location.pathname === `/archimede/${route}`}
      to={`/archimede/watches`}
      draggable={false}
      {...{ isFullSize }}
    >
      {capitalize(route)}
    </HeaderItem>
  );
};

export const HeaderItem = styled(motion(Link))<{
  isActive: boolean;
  isFullSize?: boolean;
}>`
  font-weight: 300;
  font-size: 18px;
  color: ${(props) =>
    props.isActive ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.5)"};
  display: flex;
  text-decoration: none;
  align-items: center;
  height: 50px;
  justify-content: center;
  border-radius: 40px;
  padding: 26px 30px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: background-color 0.3s ease-in-out;
  border-color: ${(props) =>
    props.isActive ? "rgba(255, 255, 255, 0.2)" : "transparent"};
  user-select: none;
  width: ${(props) => (props.isFullSize ? "100%" : undefined)};
  &:hover {
    background-color: ${(props) =>
      props.isActive ? undefined : "rgba(255, 255, 255, 0.02)"};
  }
`;

export default NavItem;
