import React, { useState } from "react";
import styled from "styled-components";
import { P } from "../../../components/Typography";
import { Config, Option } from "../../constants";
import { capitalize } from "../../helper";

interface Props {
  mainCategory: string;
  category: string;
  options: Option[];
  config: Config;
  setConfig: (config: Config) => void;
}

const OptionSlider: React.FC<Props> = ({
  mainCategory,
  category,
  options,
  config,
  setConfig,
}) => {
  /*   const [isPresent, safeToRemove] = usePresence();
  useEffect(() => {
    // Remove from DOM 1000ms after being removed from React
    !isPresent && setTimeout(safeToRemove, 100);
  }, [isPresent]); */

  const [hoveredOption, setHoveredOption] = useState<number | null>(null);

  const mainCategoryConfig = config[mainCategory as keyof Config];
  const categoryConfig =
    mainCategoryConfig[category as keyof typeof mainCategoryConfig];

  const lockInSelection = (idx: number) => {
    setConfig({
      ...config,
      [mainCategory]: {
        ...config[mainCategory as keyof typeof config],
        [category]: options[idx].value,
      },
    });
  };

  const nrOfOptions = options.length;
  const selectionIndex = options.findIndex(
    (option) => option.value === categoryConfig
  );

  return (
    <React.Fragment>
      <P>{capitalize(category)}</P>
      <Background>
        <Selection {...{ nrOfOptions }} selectionIndex={selectionIndex} />
        {options.map((option, idx) => {
          const isHovered = hoveredOption === idx;
          const isSelected = categoryConfig === option.value;
          return (
            <OptionItem
              key={`${mainCategory}-${category}-${idx}`}
              onClick={() => lockInSelection(idx)}
              onMouseEnter={() => setHoveredOption(idx)}
              onMouseLeave={() => setHoveredOption(null)}
            >
              <HoverSelection isActive={isHovered} />
              {option.extra.colorHex && (
                <ColorDisplay colorHex={option.extra.colorHex} />
              )}

              <P style={{ opacity: isSelected ? 1 : 0.6 }}>
                {capitalize(option.extra.displayName)}
              </P>
            </OptionItem>
          );
        })}
      </Background>
    </React.Fragment>
  );
};

const Background = styled.div`
  background-color: rgba(255, 255, 255, 0.04);
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 45px;
  border-radius: 40px;
  cursor: pointer;
  position: relative;
`;

const OptionItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  gap: 10px;
  transition: background-color 0.3s ease-in-out;
  z-index: 1;
  position: relative;
`;

const Selection = styled.div<{ nrOfOptions: number; selectionIndex: number }>`
  height: 100%;
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 0.13);
  position: absolute;
  transform: ${({ selectionIndex }) => `translateX(${selectionIndex * 100}%)`};
  transition: transform 0.3s ease-in-out;
  width: ${({ nrOfOptions }) => 100 / nrOfOptions}%;
  z-index: 1;
`;

const HoverSelection = styled.div<{
  isActive: boolean;
}>`
  border-radius: 28px;
  background-color: ${({ isActive }) =>
    isActive ? "rgba(255, 255, 255, 0.05)" : "transparent"};
  position: absolute;
  transition: background-color 0.6s ease-in-out;
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  z-index: 1;
`;

const ColorDisplay = styled.div<{ colorHex: string }>`
  height: 20px;
  width: 20px;
  background-color: ${({ colorHex }) => colorHex};
  border-radius: 10px;
`;

export default OptionSlider;
