import { motion } from "motion/react";
import React from "react";
import styled from "styled-components";
import { RowDiv } from "../../../components/Layout";
import { H3 } from "../../../components/Typography";
import { CONFIG_OPTIONS } from "../../constants";
import { capitalize } from "../../helper";

interface Props {
  activeMainCategory: string;
  handleExit: (newMainCategory: string) => void;
}

const CategorySelector: React.FC<Props> = ({
  activeMainCategory,
  handleExit,
}) => {
  return (
    <Background>
      {Object.entries(CONFIG_OPTIONS).map(([mainCategory, categories], idx) => {
        const isActive = activeMainCategory === mainCategory;
        return (
          <MotionButton
            type="glass"
            isActive={isActive}
            onClick={() => {
              activeMainCategory !== mainCategory && handleExit(mainCategory);
            }}
          >
            <div
              style={{
                width: 50,
                height: 50,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require(`../../assets/icons/${mainCategory}.png`)}
                height={20}
                width={"auto"}
                draggable={false}
              />
            </div>
            <motion.div
              id={mainCategory}
              initial={{
                opacity: 0,
                width: 0,
              }}
            >
              <H3 style={{ padding: "0px 20px 0px 5px" }}>
                {capitalize(mainCategory)}
              </H3>
            </motion.div>
          </MotionButton>
        );
      })}
    </Background>
  );
};

const Background = styled(RowDiv)`
  gap: 10px;
  margin-left: 20px;
`;

const MotionButton = styled(motion.button)<{
  type: "action" | "glass";
  isActive: boolean;
}>`
  font-size: 14px;
  padding: 0px;
  font-weight: 600;
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  align-self: flex-start;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: background-color 0.5s ease-in-out;
  overflow: hidden;
  /* opacity: ${(props) => (props.isActive ? 1 : 0.3)}; */
  backdrop-filter: blur(30px);
  box-shadow: inset 0 0 2px rgba(255, 255, 255, 0.2);
  background-color: ${(props) =>
    props.isActive ? "rgba(255, 255, 255, 0.3)" : "rgba(255, 255, 255, 0.2)"};
  &:hover {
    background-color: ${(props) =>
      props.isActive
        ? "rgba(255, 255, 255, 0.2)"
        : "rgba(255, 255, 255, 0.08)"};
  }
`;

export default CategorySelector;
