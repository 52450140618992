import { motion } from "motion/react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { CONFIG_OPTIONS } from "./constants";
import Header from "./header";
import Configurator from "./product";

export interface ImageProps {
  value: string;
  image: string;
}

interface Props {}

const Archimede: React.FC<Props> = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const IMAGES: { [key: string]: ImageProps[] } = {
    strap: [],
    dial: [],
    hands: [],
    case: [],
    crown: [],
    logo: [],
  };

  const populateImages = () => {
    CONFIG_OPTIONS.strap.type.forEach((option) => {
      const strapVariation = require(`./assets/pilot/36/strap/type/${option.value}.png`);
      IMAGES.strap.push({ value: option.value, image: strapVariation });
    });
    CONFIG_OPTIONS.dial.color.forEach((color) => {
      CONFIG_OPTIONS.dial.date.forEach((date) => {
        const dialVariation = require(`./assets/pilot/36/dial/date/${color.value}-${date.value}.png`);
        IMAGES.dial.push({
          value: `${color.value}-${date.value}`,
          image: dialVariation,
        });
      });
    });
    CONFIG_OPTIONS.dial.hands.forEach((option) => {
      const handsVariation = require(`./assets/pilot/36/dial/hands/${option.value}.png`);
      IMAGES.hands.push({ value: option.value, image: handsVariation });
    });
    CONFIG_OPTIONS.case.color.forEach((option) => {
      const caseVariation = require(`./assets/pilot/36/case/color/${option.value}.png`);
      IMAGES.case.push({ value: option.value, image: caseVariation });
    });

    CONFIG_OPTIONS.case.handedness.forEach((handedness) => {
      CONFIG_OPTIONS.case.color.forEach((color) => {
        const crownVariation = require(`./assets/pilot/36/case/handedness/${color.value}-${handedness.value}.png`);
        IMAGES.crown.push({
          value: `${color.value}-${handedness.value}`,
          image: crownVariation,
        });
      });
    });

    CONFIG_OPTIONS.dial.logo.forEach((logo) => {
      CONFIG_OPTIONS.dial.color.forEach((color) => {
        const logoVariation = require(`./assets/pilot/36/dial/logo/${color.value}-${logo.value}.png`);
        IMAGES.logo.push({
          value: `${color.value}-${logo.value}`,
          image: logoVariation,
        });
      });
    });
  };

  populateImages();

  return (
    <Background onClick={() => isMenuOpen && setIsMenuOpen(false)}>
      <Content>
        <Header {...{ isMenuOpen }} {...{ setIsMenuOpen }} />
        <Routes>
          <Route path="watches" element={<Configurator {...{ IMAGES }} />} />
        </Routes>
      </Content>
    </Background>
  );
};

const Background = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  z-index: 0;
  width: 100vw;
  min-height: 100vh;
  background: linear-gradient(190deg, #2e2f30 0, #101011 100%);
`;

const Content = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default Archimede;
