import { AnimatePresence, motion } from "motion/react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { RowDiv } from "../../components/Layout";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import BurgerMenu from "../assets/icons/burger-menu.svg";
import Close from "../assets/icons/close.svg";
import ShoppingCart from "../assets/icons/shopping-cart.svg";
import Logo from "../assets/logo_alt.svg";
import NavItem, { HeaderItem } from "./NavItem";

interface Props {
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header: React.FC<Props> = ({ isMenuOpen, setIsMenuOpen }) => {
  const { size } = useWindowDimensions();
  const location = useLocation();

  return (
    <Background style={{ height: size === "mobile" ? 60 : 100 }}>
      <LogoWrapper
        to="/archimede/watches#"
        style={{ textDecoration: "none" }}
        draggable={false}
      >
        <img
          src={Logo}
          style={{ height: 25, width: "auto" }}
          draggable={false}
        />
      </LogoWrapper>
      {size !== "mobile" && (
        <>
          <NavItem route="watches" />
          <NavItem route="accessories" />
          <NavItem route="history" />
          <NavItem route="contact" />
        </>
      )}
      <RowDiv>
        <CartWrapper>
          <HeaderItem
            style={{ width: 50, height: 50, padding: 0 }}
            isActive={location.pathname === `/shopping-cart`}
            to={`/archimede/watches`}
            draggable={false}
          >
            <img src={ShoppingCart} />
          </HeaderItem>
        </CartWrapper>
        {size === "mobile" && (
          <BurgerWrapper onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <img src={isMenuOpen ? Close : BurgerMenu} />
          </BurgerWrapper>
        )}
      </RowDiv>
      <AnimatePresence>
        {isMenuOpen && size === "mobile" && (
          <MobileMenuBackground
            onClick={() => setIsMenuOpen(false)}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <NavItem isFullSize route="watches" />
            <NavItem isFullSize route="accessories" />
            <NavItem isFullSize route="history" />
            <NavItem isFullSize route="contact" />
          </MobileMenuBackground>
        )}
      </AnimatePresence>
    </Background>
  );
};

const Background = styled(RowDiv)`
  width: 100%;
  height: 100px;
  justify-content: center;
  justify-content: space-between;
  padding: 0px 20px;
  position: relative;
  position: sticky;
  top: 0;
  z-index: 1000000000;
`;

const LogoWrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 170px;
`;

const CartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const BurgerWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  justify-content: center;
  border-radius: 40px;
  width: 50px;
  margin-left: 10px;
`;

const MobileMenuBackground = styled(motion.div)`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  top: 100px;
  left: 20px;
  width: calc(100% - 40px);
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  padding: 20px;
  backdrop-filter: blur(40px);
`;

export default Header;
