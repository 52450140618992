import { motion } from "motion/react";
import React from "react";
import styled from "styled-components";
import { ColDiv } from "../../components/Layout";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { ImageProps } from "../App";
import { Config } from "../constants";
import ConfigPanel from "./configPanel";
import ViewPanel from "./viewPanel";
import ProductDescription from "./viewPanel/ProductDescription";

interface Props {
  IMAGES: {
    [key: string]: ImageProps[];
  };
}

const Configurator: React.FC<Props> = ({ IMAGES }) => {
  const { size } = useWindowDimensions();

  const [config, setConfig] = React.useState<Config>({
    case: {
      diameter: "36",
      color: "steel",
      handedness: "right",
    },
    movement: {
      type: "automatic",
    },
    dial: {
      color: "black",
      hands: "black",
      date: "without",
      logo: "with",
    },
    strap: {
      type: "steel",
    },
  });

  return (
    <Background size={size}>
      <ViewPanel {...{ config }} {...{ IMAGES }} />
      <ColumnWrapper
        style={{
          marginTop: size === "desktop" ? 90 : undefined,
          paddingRight: size === "desktop" ? 30 : undefined,
        }}
      >
        <ConfigPanel {...{ config }} {...{ setConfig }} />
        <ProductDescription {...{ config }} />
      </ColumnWrapper>
    </Background>
  );
};

const Background = styled(motion(ColDiv))<{
  size: "desktop" | "mobile" | "tablet";
}>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.size !== "desktop" ? "1fr" : "1fr 1fr"};
  grid-template-rows: "auto auto";
  max-width: ${(props) => (props.size !== "desktop" ? "600px" : undefined)};
  gap: 30px;
  position: relative;
`;

const ColumnWrapper = styled(motion(ColDiv))`
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export default Configurator;
