import { useLayoutEffect, useRef, useState } from "react";

export const useInitialDivHeight = () => {
  const ref = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState(0);
  useLayoutEffect(() => {
    if (ref.current) setHeight(ref.current.offsetHeight);
  }, []);
  return { ref, height };
};
