import styled from "styled-components";

import { motion } from "motion/react";
import React from "react";

interface Props {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  isActive?: boolean;
  type?: "action" | "glass";
  iconLeft?: string;
  onClick?: () => void;
}

const Button: React.FC<Props> = ({
  children,
  style,
  onClick,
  isActive = true,
  type = "action",
  iconLeft,
}) => {
  return (
    <Background {...{ isActive }} {...{ type }} style={style} onClick={onClick}>
      {iconLeft && (
        <img src={iconLeft} height={20} width={"auto"} draggable={false} />
      )}
      {children}
    </Background>
  );
};

export default Button;

export const Background = styled(motion.button)<{
  type: "action" | "glass";
  isActive: boolean;
}>`
  font-size: 14px;
  font-weight: 600;
  color: white;
  border: none;
  padding: 2px 20px 0px 20px;
  border-radius: 30px;
  cursor: pointer;
  white-space: nowrap;
  align-self: flex-start;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.isActive ? 1 : 0.3)};
  transition: width 0.3s ease-in-out;
  background-color: ${(props) =>
    props.type === "action" ? "#006edd" : "rgba(255, 255, 255, 0.2)"};
`;
