import styled from "styled-components";

export const H1 = styled.h1`
  color: white;
  font-size: 32px;
  margin: 2px 0px;
`;

export const H2 = styled.h2`
  color: white;
  font-size: 18px;
  margin: 0px;
  margin: 2px 0px;
`;

export const H3 = styled.h3`
  color: white;
  font-size: 18px;
  font-weight: 400;
  margin: 2px 0px;
`;

export const P = styled.p`
  color: white;
  font-size: 14px;
  margin: 2px 0px;
  font-weight: 400;
`;

export const LightP = styled.p`
  color: white;
  font-size: 14px;
  opacity: 0.6;
  margin: 2px 0px;
`;
