import styled from "styled-components";

export const ColDiv = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: white;
  margin: 10px 0px 20px 0px;
  opacity: 0.2;
`;

export const Spacer = styled.div<{ height?: number; width?: number }>`
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  background-color: green;
`;
