import { AnimationOptions, useAnimate } from "motion/react";
import React, { useEffect } from "react";
import styled from "styled-components";
import useScrolledToBottom from "../../../hooks/useScrolledToBottom";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { Config } from "../../constants";
import Tile from "../../Tile";
import CategorySelector from "./CategorySelector";
import ConfigTile from "./ConfigTile";

interface Props {
  config: Config;
  setConfig: React.Dispatch<React.SetStateAction<Config>>;
}

export interface MainCategory {
  [key: string]: string[];
}

const ConfigPanel: React.FC<Props> = ({ config, setConfig }) => {
  const { size } = useWindowDimensions();
  const isBottom = useScrolledToBottom(50);
  const [activeMainCategory, setActiveMainCategory] =
    React.useState<string>("case");

  /* useEffect(() => {
    isBottom &&
    window.scrollTo({
      top: document.documentElement.scrollHeight,
    });
  }, [document.documentElement.scrollHeight]); */

  const [scope, animate] = useAnimate();
  const regularTransition: AnimationOptions = {
    duration: 0.4,
    ease: "easeInOut",
  };
  const shortTransition: AnimationOptions = {
    duration: 0.3,
    ease: "easeInOut",
  };

  const handleExit = async (newMainCategory: string) => {
    console.log("EXIT", activeMainCategory);
    animate(
      `#grid-container-${activeMainCategory}`,
      { opacity: 0 },
      shortTransition
    );
    animate(`#title-${activeMainCategory}`, { opacity: 0 }, shortTransition);
    await animate(`#${activeMainCategory}`, { opacity: 0 }, shortTransition);
    animate(
      `#grid-container-${activeMainCategory}`,
      { height: 0 },
      regularTransition
    );
    await animate(`#${activeMainCategory}`, { width: 0 }, regularTransition);
    setActiveMainCategory(newMainCategory);
  };

  const handleEntrance = async () => {
    console.log("ENTER", activeMainCategory);
    animate(
      `#grid-container-${activeMainCategory}`,
      { height: "auto" },
      regularTransition
    );
    await animate(
      `#${activeMainCategory}`,
      { width: "auto" },
      regularTransition
    );
    animate(
      `#grid-container-${activeMainCategory}`,
      { opacity: 1 },
      regularTransition
    );
    animate(`#title-${activeMainCategory}`, { opacity: 1 }, shortTransition);
    await animate(`#${activeMainCategory}`, { opacity: 1 }, shortTransition);
  };

  useEffect(() => {
    handleEntrance();
  }, [activeMainCategory]);

  return (
    <Background ref={scope}>
      <CategorySelector {...{ activeMainCategory }} {...{ handleExit }} />
      <Tile style={{ flexDirection: "column", gap: 80, border: "none" }}>
        <ConfigTile
          {...{ config }}
          mainCategory={activeMainCategory}
          {...{ setConfig }}
        />
      </Tile>
    </Background>
  );
};

const Background = styled.div`
  gap: 20px;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export default ConfigPanel;
