import React from "react";
import styled from "styled-components";
import Button from "../../../components/Button";
import { ColDiv, RowDiv } from "../../../components/Layout";
import { H2, P } from "../../../components/Typography";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

interface Props {}

const ProductDescription: React.FC<Props> = ({}) => {
  const { size } = useWindowDimensions();

  return (
    <Background
      style={size === "mobile" ? { paddingLeft: 20, paddingRight: 20 } : {}}
    >
      <RowDiv style={{ gap: 18, justifyContent: "space-between" }}>
        <ColDiv>
          <P>Total Price</P>
          <H2>1,120.00 €</H2>
        </ColDiv>

        <Button
          onClick={() => {
            console.log("Buy Now");
          }}
        >
          <P>Buy Now</P>
        </Button>
      </RowDiv>
    </Background>
  );
};

const Background = styled.div`
  display: flex;
  flex-direction: column;
  background-color: "green";
  width: 100%;
  gap: 10px;
  padding-bottom: 30px;
`;

export default ProductDescription;
