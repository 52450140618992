import posthog from "posthog-js";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Archimede from "./Archimede/App";
import Home from "./Home";
import Portal from "./Portal";
import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

posthog.init("phc_ERXuKA3vUMwcBjyHaypAG0SWftVPDBwnPQaAVbskfSB", {
  api_host: "https://eu.i.posthog.com",
  person_profiles: "always", // or 'always' to create profiles for anonymous users as well
});

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/archimede/*" element={<Archimede />} />
        <Route path="/portal/*" element={<Portal />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
