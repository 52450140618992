import { Config, CONFIG_OPTIONS } from "./constants";

export function capitalize(val: string) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

export const getMainCategorySummary = (
  config: Config,
  mainCategory: string
) => {
  let result = "";
  const objLength = Object.keys(
    config[mainCategory as keyof typeof config]
  ).length;
  {
    const typedMainCategory = mainCategory as keyof typeof CONFIG_OPTIONS;

    Object.entries(config[mainCategory as keyof typeof config]).map(
      ([category, option], idx) => {
        const typedCategory =
          category as keyof (typeof CONFIG_OPTIONS)[typeof typedMainCategory];
        const categoryConfig = CONFIG_OPTIONS[typedMainCategory][
          typedCategory
        ] as {
          value: string;
          extra: { displayName: string; detailedName: string };
        }[];
        const detailedName = categoryConfig.find(
          (configOption) => configOption["value"] === option
        )?.extra.detailedName;

        result += detailedName;

        if (idx < objLength - 1) {
          result += " - ";
        }
      }
    );
  }
  return result;
};

export const areObjectsEqual = (obj1: any, obj2: any): boolean => {
  // Check if both are objects
  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 === null ||
    obj2 === null
  ) {
    return obj1 === obj2; // Compare primitive values
  }

  // Check if they have the same keys
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  // Recursively compare values for each key
  for (const key of keys1) {
    if (!keys2.includes(key) || !areObjectsEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};
