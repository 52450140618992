import { useEffect, useState } from "react";

const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
    size: "desktop" | "mobile" | "tablet";
  }>({
    width: window.innerWidth,
    height: window.innerHeight,
    size:
      window.innerWidth > 1000
        ? "desktop"
        : window.innerWidth > 850
        ? "tablet"
        : "mobile",
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
        size:
          window.innerWidth > 1000
            ? "desktop"
            : window.innerWidth > 850
            ? "tablet"
            : "mobile",
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return dimensions;
};

export default useWindowDimensions;
