import React from "react";
import styled from "styled-components";
import "../Home/app.css";
import Container from "./Container";

interface Props {}

export interface PortalVersion {
  title: string;
  status: "latest" | "upcoming";
  distance: string;
  duration: string;
  maxSpeed: string;
  payload: string;
}

const versions: PortalVersion[] = [
  {
    title: "V0",
    status: "latest",
    distance: "200 m",
    duration: "15 s",
    maxSpeed: "50 km/h",
    payload: "0 kg",
  },
  {
    title: "V1",
    status: "upcoming",
    distance: "5 km",
    duration: "10 min",
    maxSpeed: "50 km/h",
    payload: "0 kg",
  },
];

const Portal: React.FC<Props> = () => {
  return (
    <Background>
      <div className="page">
        <div id="name">
          <h1>DANIEL FRIEDEBOLD</h1>
        </div>
        {versions.map((version) => (
          <Container {...{ version }} />
        ))}
      </div>
    </Background>
  );
};

const Background = styled.div`
  display: flex;
  background-color: red;
`;
export default Portal;
