import { motion } from "motion/react";
import React from "react";
import styled from "styled-components";
import { Divider } from "../../../components/Layout";
import { H2, LightP } from "../../../components/Typography";
import { Config, CONFIG_OPTIONS } from "../../constants";
import { capitalize, getMainCategorySummary } from "../../helper";
import OptionSlider from "./OptionSlider";

interface Props {
  config: Config;
  mainCategory: string;
  setConfig: (config: any) => void;
}

const ConfigTile: React.FC<Props> = ({ config, mainCategory, setConfig }) => {
  const categories = CONFIG_OPTIONS[mainCategory as keyof Config];

  return (
    <Background>
      <motion.div id={`title-${mainCategory}`} initial={{ opacity: 0 }}>
        <H2>{capitalize(mainCategory)}</H2>
        <LightP>{getMainCategorySummary(config, mainCategory)}</LightP>
      </motion.div>
      <Divider />
      <GridContainer
        nrOfRows={Object.keys(categories).length}
        initial={{ opacity: 0, height: 0 }}
        id={`grid-container-${mainCategory}`}
      >
        {Object.entries(categories).map(([category, options]) => {
          return (
            <OptionSlider
              key={category}
              mainCategory={mainCategory}
              category={category}
              options={options}
              {...{ setConfig }}
              {...{ config }}
            />
          );
        })}
      </GridContainer>
    </Background>
  );
};

const Background = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
`;

const GridContainer = styled(motion.div)<{ nrOfRows: number }>`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 20px;
  row-gap: ${(props) => (props.nrOfRows > 1 ? "20px" : "0px")};
  overflow: hidden;
`;

export default ConfigTile;
